export const WEBHOOK_RESOURCE = {
    type: 'webhook',
    description: 'This is the name of your webhook.',
    runAsAuthedUserDescription: 'This is where you can choose the authentication method for your webhook. You can authenticate by specifying "api-key", "email"/"password", or "jwtTokenString" in the body of your request.',
    runAsAuthedUserRadioDescription: 'Authenticate your webhook with user credentials provided in the body of the request, and execute with the permissions of that user.',
};
export var AuthenticationMethod;
(function (AuthenticationMethod) {
    AuthenticationMethod["AuthedUser"] = "runAsAuthedUser";
    AuthenticationMethod["UserId"] = "runAsUserId";
    AuthenticationMethod["UserIdScriptSource"] = "runAsUserIdScriptSource";
    AuthenticationMethod["System"] = "runAsSystem";
})(AuthenticationMethod || (AuthenticationMethod = {}));
export const DEFAULT_USER_SCRIPT_SOURCE = `exports = function(payload) {
  /*
    This function should return the id of a user to run the webhook as. For example:
    if (payload.condition) {
     return "5a3db0b88b1f2cabd68ed4d9";
    }
    return "5908ffd3d383ad67b0fff290";
  */
};`;
