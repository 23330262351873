var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { JsonObject, JsonProperty } from 'json2typescript';
let Repository = class Repository {
    id = '';
    fullName = '';
    url = '';
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('id')
], Repository.prototype, "id", void 0);
__decorate([
    JsonProperty('full_name')
], Repository.prototype, "fullName", void 0);
__decorate([
    JsonProperty('url')
], Repository.prototype, "url", void 0);
Repository = __decorate([
    JsonObject('Repository')
], Repository);
export { Repository };
let AutomaticDeploymentConfig = class AutomaticDeploymentConfig {
    enabled = false;
    providerType = undefined;
    installationIds = [];
    repository = undefined;
    branch = undefined;
    directory = undefined;
    lastModified = 0;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('enabled')
], AutomaticDeploymentConfig.prototype, "enabled", void 0);
__decorate([
    JsonProperty('provider', String, true)
], AutomaticDeploymentConfig.prototype, "providerType", void 0);
__decorate([
    JsonProperty('installation_ids')
], AutomaticDeploymentConfig.prototype, "installationIds", void 0);
__decorate([
    JsonProperty('repository', Repository, true)
], AutomaticDeploymentConfig.prototype, "repository", void 0);
__decorate([
    JsonProperty('branch', String, true)
], AutomaticDeploymentConfig.prototype, "branch", void 0);
__decorate([
    JsonProperty('directory', String, true)
], AutomaticDeploymentConfig.prototype, "directory", void 0);
__decorate([
    JsonProperty('last_modified')
], AutomaticDeploymentConfig.prototype, "lastModified", void 0);
AutomaticDeploymentConfig = __decorate([
    JsonObject('AutomaticDeploymentConfig')
], AutomaticDeploymentConfig);
export { AutomaticDeploymentConfig };
let PartialCodeDeploy = class PartialCodeDeploy {
    uiDraftsDisabled = false;
    automaticDeploymentConfig = new AutomaticDeploymentConfig();
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('ui_drafts_disabled')
], PartialCodeDeploy.prototype, "uiDraftsDisabled", void 0);
__decorate([
    JsonProperty('automatic_deployment')
], PartialCodeDeploy.prototype, "automaticDeploymentConfig", void 0);
PartialCodeDeploy = __decorate([
    JsonObject('PartialCodeDeploy')
], PartialCodeDeploy);
export { PartialCodeDeploy };
let Installation = class Installation {
    id = '';
    providerType = '';
    installationId = '';
    ownerId = '';
    authenticatedRepositories = [];
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('_id')
], Installation.prototype, "id", void 0);
__decorate([
    JsonProperty('provider')
], Installation.prototype, "providerType", void 0);
__decorate([
    JsonProperty('installation_id')
], Installation.prototype, "installationId", void 0);
__decorate([
    JsonProperty('owner_id')
], Installation.prototype, "ownerId", void 0);
__decorate([
    JsonProperty('authenticated_repositories', [Repository])
], Installation.prototype, "authenticatedRepositories", void 0);
Installation = __decorate([
    JsonObject('Installation')
], Installation);
export { Installation };
let AutomaticDeploymentConfigUpdateRequest = class AutomaticDeploymentConfigUpdateRequest {
    enabled = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('enabled', Boolean, true)
], AutomaticDeploymentConfigUpdateRequest.prototype, "enabled", void 0);
AutomaticDeploymentConfigUpdateRequest = __decorate([
    JsonObject('AutomaticDeploymentConfigUpdateRequest')
], AutomaticDeploymentConfigUpdateRequest);
export { AutomaticDeploymentConfigUpdateRequest };
let CodeDeployUpdateRequest = class CodeDeployUpdateRequest {
    uiDraftsDisabled = undefined;
    automaticDeploymentConfig = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('ui_drafts_disabled', Boolean, true)
], CodeDeployUpdateRequest.prototype, "uiDraftsDisabled", void 0);
__decorate([
    JsonProperty('automatic_deployment', AutomaticDeploymentConfigUpdateRequest, true)
], CodeDeployUpdateRequest.prototype, "automaticDeploymentConfig", void 0);
CodeDeployUpdateRequest = __decorate([
    JsonObject('CodeDeployUpdateRequest')
], CodeDeployUpdateRequest);
export { CodeDeployUpdateRequest };
let GitHubBranch = class GitHubBranch {
    name = '';
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('name')
], GitHubBranch.prototype, "name", void 0);
GitHubBranch = __decorate([
    JsonObject('GitHubBranch')
], GitHubBranch);
export { GitHubBranch };
let GitHubDirectory = class GitHubDirectory {
    name = '';
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('name')
], GitHubDirectory.prototype, "name", void 0);
GitHubDirectory = __decorate([
    JsonObject('GitHubDirectory')
], GitHubDirectory);
export { GitHubDirectory };
let GithubGroupAuthenticationResponse = class GithubGroupAuthenticationResponse {
    requestId = '';
    requestTimestamp = '';
    githubRedirectUrl = '';
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('request_id')
], GithubGroupAuthenticationResponse.prototype, "requestId", void 0);
__decorate([
    JsonProperty('request_timestamp')
], GithubGroupAuthenticationResponse.prototype, "requestTimestamp", void 0);
__decorate([
    JsonProperty('github_redirect_url')
], GithubGroupAuthenticationResponse.prototype, "githubRedirectUrl", void 0);
GithubGroupAuthenticationResponse = __decorate([
    JsonObject('GithubGroupAuthenticationResponse')
], GithubGroupAuthenticationResponse);
export { GithubGroupAuthenticationResponse };
let GithubPushAppRequest = class GithubPushAppRequest {
    draftId = undefined;
    deploymentName = undefined;
    force = undefined;
    frontendDirectory = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('draftId', String, true)
], GithubPushAppRequest.prototype, "draftId", void 0);
__decorate([
    JsonProperty('deploymentName', String, true)
], GithubPushAppRequest.prototype, "deploymentName", void 0);
__decorate([
    JsonProperty('force', Boolean, true)
], GithubPushAppRequest.prototype, "force", void 0);
__decorate([
    JsonProperty('frontendDirectory', String, true)
], GithubPushAppRequest.prototype, "frontendDirectory", void 0);
GithubPushAppRequest = __decorate([
    JsonObject('GithubPushAppRequest')
], GithubPushAppRequest);
export { GithubPushAppRequest };
let EnableTTLInstallationAutoDeployRequest = class EnableTTLInstallationAutoDeployRequest {
    githubAuthRequestId = '';
    repositoryId = '';
    branch = '';
    directory = '';
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('github_auth_request_id')
], EnableTTLInstallationAutoDeployRequest.prototype, "githubAuthRequestId", void 0);
__decorate([
    JsonProperty('repository_id')
], EnableTTLInstallationAutoDeployRequest.prototype, "repositoryId", void 0);
__decorate([
    JsonProperty('branch')
], EnableTTLInstallationAutoDeployRequest.prototype, "branch", void 0);
__decorate([
    JsonProperty('directory')
], EnableTTLInstallationAutoDeployRequest.prototype, "directory", void 0);
EnableTTLInstallationAutoDeployRequest = __decorate([
    JsonObject('EnableTTLInstallationAutoDeployRequest')
], EnableTTLInstallationAutoDeployRequest);
export { EnableTTLInstallationAutoDeployRequest };
