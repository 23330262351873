/* eslint-disable import/no-import-module-exports */
/* eslint-disable no-console */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import LeafyGreenProvider from '@leafygreen-ui/leafygreen-provider';
import * as Sentry from '@sentry/react';
import Analytics from 'heliotrope';

import ExperimentManager from 'baas-ui/ab/ExperimentManager';
import { beforeSend, errorHandlerNotify } from 'baas-ui/error_util';
import { BaasAdminClient } from 'admin-sdk';

import ThemeProvider from './theme/theme-provider';
import { EventLogger } from './tracking/types';
import { getCloudStartupPreferences, parseThemePreference } from './cloudStartupPreferences';
import Router from './router';
import StitchUI from './stitch_ui';
import { EventTracker } from './tracking';
import { BaasEnvironment } from './types';
import { hasRealmInUrl } from './urls';
import { getHeliotropeEnvironment } from './util';

require('../static/favicon.ico');
require('../static/main.less');

// TODO(BAAS-28077): remove conditional once realm domain is unsupported in UI
let adminUrl = window.settings.adminUrl;
if (hasRealmInUrl(window.location.href) && window.settings.legacyAdminUrl) {
  adminUrl = window.settings.legacyAdminUrl;
}
const adminClient = new BaasAdminClient(adminUrl, { requestOrigin: 'mongodb-baas-ui' });

const { segmentKey = '', environmentDesc = '', uiSentryDsn } = window.settings;

if (!process.env.DISABLE_SENTRY && uiSentryDsn && environmentDesc !== '') {
  Sentry.init({
    dsn: uiSentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: environmentDesc === BaasEnvironment.Prod,
        blockAllMedia: environmentDesc === BaasEnvironment.Prod,
        maskAllText: environmentDesc === BaasEnvironment.Prod,
      }),
    ],
    environment: environmentDesc,
    release: process.env.UI_VERSION || '',
    // Options added to the Browser SDK's init options that are specific for Replay.
    replaysOnErrorSampleRate: environmentDesc === BaasEnvironment.Prod ? 0.5 : 1.0,
    replaysSessionSampleRate: 0,
    // Set sample rate to capture transactions for performance monitoring.
    tracesSampleRate: 0,
    // The sample rate for profiling.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 0,
    // A global sample rate to apply to all events.
    sampleRate: environmentDesc === BaasEnvironment.Prod ? 1 : 0.5,
    beforeSend,
  });
}

const eventLogger: EventLogger = {
  init: (userId) => {
    console.log('Initialize Tracking for user:', userId);
  },
  reset: () => {
    console.log('Reset Tracking state');
  },
  logEvent: (event, properties) => {
    console.log('Tracking Event:', event, properties);
    return Promise.resolve();
  },
  updateState: (stateOptions) => {
    console.log('Update Tracking State:', stateOptions);
  },
};

if (segmentKey) {
  eventLogger.init = (userId) =>
    Analytics.init({
      segmentId: segmentKey,
      appId: 'appServices',
      integrations: { Amplitude: true },
      persistedProperties: { cloud_user_id: userId },
      environment: getHeliotropeEnvironment(environmentDesc),
    }).catch((err) => errorHandlerNotify(err));

  eventLogger.reset = () => Analytics.reset();

  eventLogger.logEvent = (event, properties) => Analytics.track({ eventName: event, properties });

  eventLogger.updateState = (stateOptions) => Analytics.updateState(stateOptions);
}

ExperimentManager.init(environmentDesc);

getCloudStartupPreferences(window.settings.cloudUIBaseUrl).then((startupPreferences) => {
  const settings = { ...window.settings, hasAcknowledgedDarkMode: startupPreferences.hasAcknowledgedDarkMode };
  const ui = new StitchUI(adminClient, process.env.NODE_ENV, settings);
  EventTracker.register({ store: ui.store, env: environmentDesc, eventLogger });
  if (!process.env.DISABLE_INTERCOM) {
    ui.handleIntercom(adminClient);
  }

  const themePreference = parseThemePreference(startupPreferences);

  const renderComponent = (Component) => {
    const appNode = document.getElementById('app');

    render(
      <LeafyGreenProvider>
        <ThemeProvider themePreference={themePreference}>
          <Provider store={ui.store}>
            <Component history={ui.history} />
          </Provider>
        </ThemeProvider>
      </LeafyGreenProvider>,
      appNode
    );
  };

  renderComponent(Router);

  if (module.hot) {
    module.hot.accept('./router', () => renderComponent(Router));
  }
});
