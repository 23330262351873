var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { JsonObject, JsonProperty } from 'json2typescript';
export var HostingStatus;
(function (HostingStatus) {
    HostingStatus["Ok"] = "setup_ok";
    HostingStatus["ChangeInProgress"] = "change_in_progress";
    HostingStatus["ChangeFailed"] = "change_failed";
    HostingStatus["ChangeFailedFatal"] = "change_failed_fatal";
})(HostingStatus || (HostingStatus = {}));
let DefaultDomain = class DefaultDomain {
    providerType = '';
    config = {};
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('provider_type')
], DefaultDomain.prototype, "providerType", void 0);
__decorate([
    JsonProperty('config')
], DefaultDomain.prototype, "config", void 0);
DefaultDomain = __decorate([
    JsonObject('DefaultDomain')
], DefaultDomain);
export { DefaultDomain };
let HostingConfig = class HostingConfig {
    enabled = false;
    status = HostingStatus.Ok;
    statusErrorMessage = undefined;
    defaultDomain = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('enabled')
], HostingConfig.prototype, "enabled", void 0);
__decorate([
    JsonProperty('status', String, true)
], HostingConfig.prototype, "status", void 0);
__decorate([
    JsonProperty('status_error_message', String, true)
], HostingConfig.prototype, "statusErrorMessage", void 0);
__decorate([
    JsonProperty('default_domain', DefaultDomain, true)
], HostingConfig.prototype, "defaultDomain", void 0);
HostingConfig = __decorate([
    JsonObject('HostingConfig')
], HostingConfig);
export { HostingConfig };
let AssetMetadata = class AssetMetadata {
    appId = '';
    path = '';
    hash = '';
    size = 0;
    attributes = [];
    lastModified = undefined;
    url = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('appId')
], AssetMetadata.prototype, "appId", void 0);
__decorate([
    JsonProperty('path')
], AssetMetadata.prototype, "path", void 0);
__decorate([
    JsonProperty('hash')
], AssetMetadata.prototype, "hash", void 0);
__decorate([
    JsonProperty('size')
], AssetMetadata.prototype, "size", void 0);
__decorate([
    JsonProperty('attrs')
], AssetMetadata.prototype, "attributes", void 0);
__decorate([
    JsonProperty('last_modified', Number, true)
], AssetMetadata.prototype, "lastModified", void 0);
__decorate([
    JsonProperty('url', String, true)
], AssetMetadata.prototype, "url", void 0);
AssetMetadata = __decorate([
    JsonObject('AssetMetadata')
], AssetMetadata);
export { AssetMetadata };
let AssetURL = class AssetURL {
    url = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('url', String, true)
], AssetURL.prototype, "url", void 0);
AssetURL = __decorate([
    JsonObject('AssetURL')
], AssetURL);
export { AssetURL };
let TransformAssetRequest = class TransformAssetRequest {
    moveFrom = '';
    moveTo = '';
    copyFrom = '';
    copyTo = '';
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('move_from', String, true)
], TransformAssetRequest.prototype, "moveFrom", void 0);
__decorate([
    JsonProperty('move_to', String, true)
], TransformAssetRequest.prototype, "moveTo", void 0);
__decorate([
    JsonProperty('copy_from', String, true)
], TransformAssetRequest.prototype, "copyFrom", void 0);
__decorate([
    JsonProperty('copy_to', String, true)
], TransformAssetRequest.prototype, "copyTo", void 0);
TransformAssetRequest = __decorate([
    JsonObject('TransformAssetRequest')
], TransformAssetRequest);
export { TransformAssetRequest };
