import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import Button from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import { Body } from '@leafygreen-ui/typography';
import classNames from 'classnames';
import GuidePopoverHeader from './header';
import './guide-popover.less';
const baseClassName = 'guide-popover';
const contentFooterClassName = `${baseClassName}-content-footer`;
export var TestSelector;
(function (TestSelector) {
    TestSelector["PopoverContent"] = "popover-content";
    TestSelector["PopoverContentGuide"] = "popover-content-guide";
    TestSelector["BackTextButton"] = "back-text-button";
    TestSelector["StepProgress"] = "step-progress";
    TestSelector["NextButton"] = "next-button";
    TestSelector["NextTextButton"] = "next-text-button";
})(TestSelector || (TestSelector = {}));
const resetScrollEffect = ({ element }) => {
    element.current.scrollTop = 0;
};
const GuidePopover = ({ className, children, currentStep, header, isHidden, showProgress, nextIsButton, nextLabel, totalSteps, docsLink, onBack, onClose, onClickAllGuides, onNext, isExpanded, setIsExpanded, }) => {
    const guideContentRef = useRef(null);
    React.useEffect(() => resetScrollEffect({ element: guideContentRef }), [children]);
    return (_jsxs("div", { "data-cy": "guide-popover", className: classNames(`${baseClassName}`, className), children: [_jsx(GuidePopoverHeader, { header: header, isHidden: isHidden, isExpanded: isExpanded, onClickAllGuides: onClickAllGuides, onClose: onClose, onSetIsExpanded: setIsExpanded, docsLink: docsLink }), _jsxs("div", { className: classNames(`${baseClassName}-content`, {
                    [`${baseClassName}-content-expanded`]: isExpanded,
                }), "data-test-selector": TestSelector.PopoverContent, children: [_jsx("div", { ref: guideContentRef, className: `${baseClassName}-content-guide`, "data-test-selector": TestSelector.PopoverContentGuide, children: children }), _jsxs("div", { className: contentFooterClassName, children: [_jsx("div", { className: `${contentFooterClassName}-left`, children: onBack && (_jsxs(Body, { className: `${contentFooterClassName}-nav`, onClick: onBack, role: "button", tabIndex: 0, "data-test-selector": TestSelector.BackTextButton, children: [_jsx(Icon, { glyph: "ChevronLeft" }), " Back"] })) }), _jsx("div", { className: `${contentFooterClassName}-center`, children: showProgress && (_jsx(Body, { "data-cy": "guide-popover-progress", className: `${contentFooterClassName}-center-progress`, "data-test-selector": TestSelector.StepProgress, children: `${currentStep} of ${totalSteps}` })) }), _jsx("div", { className: `${contentFooterClassName}-right`, children: nextIsButton ? (_jsx(Button, { "data-cy": "guide-popover-next-button", size: "small", onClick: onNext, variant: "primary", "data-test-selector": TestSelector.NextButton, children: nextLabel })) : (_jsxs(Body, { "data-cy": "guide-popover-next-button", className: `${contentFooterClassName}-nav`, onClick: onNext, role: "button", tabIndex: 0, "data-test-selector": TestSelector.NextTextButton, children: [nextLabel, " ", _jsx(Icon, { glyph: "ChevronRight" })] })) })] })] })] }));
};
export default GuidePopover;
