import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Button, { Size } from '@leafygreen-ui/button';
import QuestionMarkWithCircleIcon from '@leafygreen-ui/icon/dist/QuestionMarkWithCircle';
import XIcon from '@leafygreen-ui/icon/dist/X';
import IconButton from '@leafygreen-ui/icon-button';
import classNames from 'classnames';
import { passThroughProps } from 'baas-ui/common/utils/util';
import StatusAgent from './StatusAgent';
import './status-banner.less';
const baseClassName = 'status-banner';
export var Variant;
(function (Variant) {
    Variant["Error"] = "error";
    Variant["Info"] = "info";
    Variant["Success"] = "success";
    Variant["Warning"] = "warning";
})(Variant || (Variant = {}));
export var TestSelector;
(function (TestSelector) {
    TestSelector["ActionButton"] = "action-button";
    TestSelector["DismissButton"] = "dismiss-button";
    TestSelector["HelpIconButton"] = "help-icon-button";
    TestSelector["SecondaryActionButton"] = "secondary-action-button";
    TestSelector["StatusAgentC"] = "status-agent";
    TestSelector["ClearBannerIconButton"] = "clear-banner-icon-button";
})(TestSelector || (TestSelector = {}));
const StatusBanner = ({ action = () => { }, actionLabel, className = '', clearable = false, disabled = false, dismissable = false, displayTimeMillis = 0, variant = Variant.Info, helpUrl = '', message, onClear = () => { }, onHelp = () => { }, secondaryAction = () => { }, secondaryActionLabel, statusId, statusType, ...rest }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [timeoutID, setTimeoutID] = useState(null);
    useEffect(() => {
        if (displayTimeMillis) {
            setTimeoutID(window.setTimeout(() => {
                setIsOpen(false);
                onClear();
            }, displayTimeMillis));
        }
        return () => {
            timeoutID && clearTimeout(timeoutID);
        };
    }, []);
    const allClassNames = classNames(className, {
        [`${baseClassName}-is-warning`]: variant === Variant.Warning,
        [`${baseClassName}-is-error`]: variant === Variant.Error,
        [`${baseClassName}-is-info`]: variant === Variant.Info,
        [`${baseClassName}-is-success`]: variant === Variant.Success,
    });
    if (!isOpen) {
        return null;
    }
    return (_jsx(StatusAgent, { "data-testid": TestSelector.StatusAgentC, statusId: statusId, statusType: statusType, className: allClassNames, message: message, messageAddOn: _jsxs("div", { className: `${baseClassName}-control-button-container`, children: [secondaryActionLabel && (_jsx(Button, { size: Size.XSmall, onClick: secondaryAction, disabled: disabled, "data-testid": TestSelector.SecondaryActionButton, className: `${baseClassName}-action-button`, children: secondaryActionLabel })), actionLabel && (_jsx(Button, { size: Size.XSmall, onClick: action, disabled: disabled, "data-testid": TestSelector.ActionButton, className: `${baseClassName}-action-button`, children: actionLabel })), dismissable && (_jsx(Button, { size: Size.XSmall, onClick: onClear, disabled: disabled, "data-testid": TestSelector.DismissButton, className: `${baseClassName}-action-button`, children: "Dismiss" }))] }), controls: _jsxs(_Fragment, { children: [helpUrl && (_jsx(IconButton, { "aria-label": "Help Button", className: `${baseClassName}-help`, "data-testid": TestSelector.HelpIconButton, href: helpUrl, target: "_blank", rel: "noopener noreferrer", onClick: onHelp, children: _jsx(QuestionMarkWithCircleIcon, {}) })), clearable && (_jsx(IconButton, { "data-testid": TestSelector.ClearBannerIconButton, "data-cy": TestSelector.ClearBannerIconButton, "aria-label": "Dismiss Button", className: `${baseClassName}-close`, onClick: onClear, children: _jsx(XIcon, {}) }))] }), ...passThroughProps(rest) }));
};
export default StatusBanner;
